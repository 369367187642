import React, { CSSProperties } from "react";
import { Image, Container } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { COLORS, IMAGES } from "../../assets";

import "./styles/newsCard.css";

type Props = {
  title: string;
  date: string;
  subtitle: string;
  onReadMore: () => void;
};

const NewsCard = ({ title, date, subtitle, onReadMore }: Props) => {
  return (
    <Container className="card">
      <Image src={IMAGES.news_image.toString()} className="newsImg" />
      <div className="titleContainer">
        <p className="newsTitle">{title}</p>
        <p className="dateText">{date}</p>
      </div>
      <p className=".text-ellipsis--2 subtitle">{subtitle}</p>
      <p style={styles.readMore} onClick={() => onReadMore()}>
        Read More <ArrowRight />
      </p>
    </Container>
  );
};

const styles: { [key: string]: CSSProperties } = {
  card: {
    height: 512,
    width: "45%",
    padding: 20,
    margin: 15,
    background: COLORS.white,
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.15)",
    borderRadius: "20px",
  },
  newsImg: {
    width: "100%",
    height: "260px",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 25,
  },
  newsTitle: {
    fontWeight: 700,
    fontSize: 20,
    display: "flex",
    alignItems: "flex-end",
    textTransform: "capitalize",
    color: COLORS.black,
    width: "80%",
    fontFamily: "century_gothicregular",
  },
  dateText: {
    fontWeight: 500,
    fontSize: 10,
    color: COLORS.sky_blue,
    marginTop: 5,
    fontFamily: "century_gothicregular",
  },
  subtitle: {
    fontWeight: 300,
    fontSize: 16,
    color: COLORS.black,
    marginTop: 10,
    textTransform: "capitalize",
    width: "90%",
    fontFamily: "century_gothicregular",
  },
  readMore: {
    marginTop: 20,
    color: COLORS.sky_blue,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    fontFamily: "century_gothicregular",
  },
};

export default NewsCard;
