// import React from "react";
import { Image } from "react-bootstrap";

//ASSETS
import { IMAGES } from "../../assets";

//CSS

import "./styles/career.css";

// const mainContainer: React.CSSProperties = {
//   width: "100%",
//   backgroundColor: COLORS.light_gray,
//   padding: 20,
// };
// const careerTitle: React.CSSProperties = {
//   fontSize: 18,
//   fontFamily: "century_gothicregular",
//   color: COLORS.sky_blue,
//   margin: 0,
//   textAlign: "center",
// };
// const heading: React.CSSProperties = {
//   fontSize: 42,
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   margin: 0,
//   textAlign: "center",
//   marginTop: 10,
//   marginBottom: "2%",
// };
// const description: React.CSSProperties = {
//   fontSize: 18,
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   margin: 0,
//   textAlign: "center",
//   marginTop: 15,
//   lineHeight: 2.5,
// };
// const cardMainContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "center",
//   flexFlow: "wrap",
// };
// const cardContainer: React.CSSProperties = {
//   width: 483,
//   height: 230,
//   borderRadius: 20,
//   backgroundColor: COLORS.white,
//   boxShadow: " 0px 0px 30px rgba(0, 0, 0, 0.1)",
//   padding: 20,
//   margin: "2%",
// };
// const cardSubContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
//   marginTop: "2%",
// };
// const cardHeading: React.CSSProperties = {
//   fontSize: 24,
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   margin: 0,
//   paddingLeft: 15,
// };
// const cardDesc: React.CSSProperties = {
//   fontSize: 14,
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   margin: 0,
//   paddingTop: "3%",
//   lineHeight: 2,
// };

type CareerDataType = {
  id: number;
  icon: string;
  title: string;
  desc: string;
};

//Career card data
const careerData: CareerDataType[] = [
  {
    id: 1,
    icon: IMAGES.customer_service.toString(),
    title: "Clients & Customer Service",
    desc: "Leveraging our proprietary technology, often times we are able to approve loans same day!",
  },
  {
    id: 2,
    icon: IMAGES.career.toString(),
    title: "Early Career",
    desc: "Leveraging our proprietary technology, often times we are able to approve loans same day!",
  },
  {
    id: 3,
    icon: IMAGES.sales.toString(),
    title: "Sales",
    desc: "Leveraging our proprietary technology, often times we are able to approve loans same day!",
  },
  {
    id: 4,
    icon: IMAGES.technology.toString(),
    title: "Technology",
    desc: "Leveraging our proprietary technology, often times we are able to approve loans same day!",
  },
  {
    id: 5,
    icon: IMAGES.vehicle_technician.toString(),
    title: "Vehicle Technicians",
    desc: "Leveraging our proprietary technology, often times we are able to approve loans same day!",
  },
  {
    id: 6,
    icon: IMAGES.lead.toString(),
    title: "Lead",
    desc: "Leveraging our proprietary technology, often times we are able to approve loans same day!",
  },
];
const Career = () => {
  return (
    <div className={"careerMain"}>
      <p className={"careerTitle"}>CAREER</p>
      <p className={"lookingTitle"}>Looking for the right fit?</p>
      <p className={"careerDesc"}>
        We offer careers across our divisions, with dozens of brands in multiple locations. That
        means there are plenty of opportunities for you to
        <br /> move up, move around and explore new things – all under the umbrella of a company
        that cares.
        <br />
        Here are a few featured career areas:
      </p>
      <div className={"careerCardMain"}>
        {careerData.map((data, index) => (
          <div className={"careerCard"}>
            <div className={"careerCardSubContainer"}>
              <Image src={data.icon} className={"carrerIcon"} alt={"icon"} />
              <p className={"careerCardTitle"}>{data.title}</p>
            </div>
            <p className={"careerCardDesc"}>{data.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Career;
