import CustomerCard from "../components/common/CustomerCard";
import Footer from "../components/common/Footer";
import ManageAccount from "../components/common/ManageAccount";
import InsuranceHeaderSection from "../components/insurance/InsuranceHeaderSection";
import ProvidedServices from "../components/insurance/ProvidedServices";
import WhoWeAre from "../components/insurance/WhoWeAre";

const Insurance = () => {
  return (
    <div>
      <InsuranceHeaderSection />
      <WhoWeAre />
      <ProvidedServices />
      <CustomerCard headerTitle="Testimonial" customerTitle="What our customers say" />
      <ManageAccount />
      <Footer />
    </div>
  );
};

export default Insurance;
