export const IMAGES = {
  auto_debt_logo: require("./auto-debt-logo.png"),
  facebook: require("./facebook.png"),
  instagram: require("./instagram.png"),
  pinterest: require("./pinterest.png"),
  twitter: require("./twitter.png"),
  location: require("./location.png"),
  mail: require("./mail.png"),
  expert_call: require("./expert-call.png"),
  note_icon: require("./note_icon.png"),
  girl_sticker: require("./girl-sticker.png"),
  call: require("./call.png"),
  call_background: require("./call-background.png"),
  customer_1: require("./customer-1.png"),
  customer_2: require("./customer-2.png"),
  double_quotes: require("./double-quotes.png"),
  about_section: require("./about-section.png"),
  dot: require("./dot.png"),
  pattern: require("./pattern.png"),
  service1: require("./service1.png"),
  service2: require("./service2.png"),
  service3: require("./service3.png"),
  service4: require("./service4.png"),
  service_background: require("./service-background.png"),
  reach_out_main: require("./reach-out-main.png"),
  dashboard: require("./dashboard.png"),
  vector: require("./vector.png"),
  career: require("./career.png"),
  customer_service: require("./customer-service.png"),
  lead: require("./lead.png"),
  sales: require("./sales.png"),
  technology: require("./technology.png"),
  vehicle_technician: require("./vehicleTechnician.png"),
  president: require("./president.png"),
  vp_finance: require("./vp-finance.png"),
  vp_marketing: require("./vp-marketing.png"),
  avp_slaes: require("./avp-sales.png"),
  news_image: require("./news_image.png"),
  team_group: require("./team-group.png"),
  avp_councel_team: require("./avp-councel-team.png"),
  avp_engineering_team: require("./avp-engineering-team.png"),
  avp_hrbp_team: require("./avp-hrbp-team.png"),
  avp_sales_team: require("./avp-sales-team.png"),
  president_team: require("./president-team.png"),
  svp_leading_team: require("./svp-leading-team.png"),
  vp_finance_team: require("./vp-finance-team.png"),
  vp_marketing_team: require("./vp-marketing-team.png"),
  vp_portfolio_team: require("./vp-portfolio-team.png"),
  who_we_are: require("./who-we-are.png"),
  provided_services: require("./provided-services.png"),
  mask_footer: require("./mask-footer.png"),
  counter_mask: require("./counter-mask.png"),
  about_us_header: require("./about_us_header.png"),
  team_header: require("./team_header.png"),
  news_header: require("./news_header.png"),
  insurance_header: require("./insurance_header.png"),
  arrow_right: require("./arrow_right.png"),
  hamburger: require("./hamburger.png"),
  about: require("./about.png"),
  home: require("./home.png"),
  insurance: require("./insurance.png"),
  news: require("./news.png"),
  close: require("./close.png"),
  team: require("./team.png"),
  team_member1: require("./team_member1.png"),
  team_member2: require("./team_member2.png"),
  team_member3: require("./team_member3.png"),
  team_member4: require("./team_member4.png"),
  team_member5: require("./team_member5.png"),
  team_member6: require("./team_member6.png"),
  team_member7: require("./team_member7.png"),
  team_member8: require("./team_member8.png"),
  team_member9: require("./team_member9.png"),
};
