import React from "react";
import CardSection from "./CardSection";
//COMMON COMPONENTS
import { Header } from "../common/Header";
import ButtonComponent from "../common/ButtonComponent";
//ASSETS
import { COLORS, IMAGES } from "../../assets";
//CSS
const mainContainer: React.CSSProperties = {
  width: "100%",
  height: "850px",
  backgroundImage: `url(${IMAGES.insurance_header})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  padding: "0px 0px 80px 0px",
};

const subContainer: React.CSSProperties = {
  width: "600px",
  padding: "40px 110px",
  marginBottom: "70px",
};

const textContainer: React.CSSProperties = {
  width: "650px",
};

const mainTextStyle: React.CSSProperties = {
  fontFamily: "century_gothicregular",
  fontWeight: 900,
  fontSize: "67px",
  color: COLORS.white,
  marginBottom: 20,
  lineHeight: "100%",
};

const subTextStyle: React.CSSProperties = {
  fontFamily: "century_gothicregular",
  fontSize: "24px",
  color: COLORS.white,
  marginBottom: 50,
};

const buttonStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  marginBottom: "18%",
};

const InsuranceHeaderSection = () => {
  return (
    <div style={mainContainer}>
      <Header />
      <div style={subContainer}>
        <div style={textContainer}>
          <p style={mainTextStyle}>Do insurance to be safe and secure</p>
        </div>
        <div style={textContainer}>
          <p style={subTextStyle}>
            We have a flexible set of lending criteria that can be tailored to youWe have a flexible
            set of lending criteria that can
          </p>
        </div>
        <div style={buttonStyle}>
          <ButtonComponent buttonName="GET A QUOTE" height={72} width={228} isArrow={false} />
          <div style={{ marginLeft: 20 }}>
            <ButtonComponent
              buttonName="FIND AN AGENT"
              height={72}
              width={228}
              isArrow={false}
              variant={"outline"}
            />
          </div>
        </div>
      </div>
      <CardSection />
    </div>
  );
};

export default InsuranceHeaderSection;
