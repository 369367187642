import CustomerCard from "../components/common/CustomerCard";
import Footer from "../components/common/Footer";
import { Header } from "../components/common/Header";
import AboutOurCompany from "../components/dashboard/AboutOurCompany";
import OurServiceSectionFirst from "../components/dashboard/OurServiceSectionFirst";
import Counter from "../components/common/Counter";
import ManageAccount from "../components/common/ManageAccount";
import OurServiceSectionSecond from "../components/dashboard/OurServiceSectionSecond";
import ReachOut from "../components/dashboard/ReachOut";
import HeaderSection from "../components/dashboard/HeaderSection";

function Dashboard() {
  return (
    <div>
      <div className="imgboxHome">
        <Header />
        <HeaderSection />
      </div>
      <AboutOurCompany />
      <OurServiceSectionFirst />
      <OurServiceSectionSecond />
      <ReachOut />
      <Counter />
      <CustomerCard />
      <ManageAccount />
      <Footer />
    </div>
  );
}

export default Dashboard;
