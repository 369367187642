// import React from "react";
import { IMAGES } from "../../assets";
import CardContainer from "./CardContainer";
import "./styles/counter.css";
//CSS
// const mainContainer: React.CSSProperties = {
//   backgroundColor: COLORS.blue,
//   height: 286,
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   backgroundImage: `url(${IMAGES.counter_mask.toString()})`,
//   backgroundRepeat: "no-repeat",
//   backgroundSize: "cover",
// };
// const subContainer: React.CSSProperties = {
//   width: 200,
//   height: 200,
//   borderRadius: "22px",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",
//   background: "rgba(255, 255, 255, 0.06)",
//   backdropFilter: "blur(0.5px)",
//   marginRight: 55,
//   border: `1px solid ${COLORS.white_border}`,
// };
// const mainTitle: React.CSSProperties = {
//   fontSize: 26,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   fontWeight: 600,
//   margin: 0,
//   paddingTop: "6px 6px",
//   textAlign: "center",
// };
// const subTitle: React.CSSProperties = {
//   fontSize: 12,
//   fontFamily: "century_gothicregular",
//   fontWeight: 400,
//   color: COLORS.white,
//   margin: 0,
//   textAlign: "center",
// };
interface CounterData {
  id: number;
  title: string;
  subTitle: string;
}
//Raw data for cards
const counterCardData: CounterData[] = [
  {
    id: 1,
    title: "2.6 k",
    subTitle: "Loans Approved",
  },
  {
    id: 2,
    title: "175M",
    subTitle: "Funds Dispersed",
  },
  {
    id: 3,
    title: "1.5k+",
    subTitle: "Satisfied Customers",
  },
  {
    id: 4,
    title: "12",
    subTitle: "Team Members",
  },
];
const Counter = () => {
  return (
    <div className="counterSection">
      {counterCardData &&
        counterCardData.map((data, index) => (
          <CardContainer
            width={40}
            height={40}
            headerTitle={data.title}
            headerSubtitle={data.subTitle}
            icon={IMAGES.note_icon.toString()}
            subContainerStyle="subContainer"
            headerTitleStyle="mainTitle"
            headerSubtitleStyle="subTitle"
          />
        ))}
    </div>
  );
};

export default Counter;
