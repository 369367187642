// import React from "react";
import { Button } from "react-bootstrap";
import { ArrowRightShort } from "react-bootstrap-icons";
// import { useNavigate } from "react-router-dom";
//ASSETS
import { IMAGES } from "../../assets";
// import ButtonComponent from "../common/ButtonComponent";
//COMON
import CardContainer from "../common/CardContainer";

//CSS

import "./styles/headerSection.css";

type CardDataType = {
  id: number;
  title: string;
  subTitle: string;
};
//Card Data
const cardData: CardDataType[] = [
  {
    id: 1,
    title: "Same Day Approvals",
    subTitle:
      "Leveraging our propriatary technology, often times we are able to approve loans same day!",
  },
  {
    id: 1,
    title: "Same Day Approvals",
    subTitle:
      "Leveraging our propriatary technology, often times we are able to approve loans same day!",
  },
  {
    id: 1,
    title: "Same Day Approvals",
    subTitle:
      "Leveraging our propriatary technology, often times we are able to approve loans same day!",
  },
];
const HeaderSection = () => {
  // const navigate = useNavigate();
  return (
    <div>
      <div className={"headerTop"}>
        <div className="section1">
          <div className={"textContainer1"}>
            <p className={"barrierText"}>There is no barrier to your</p>
          </div>
          <div className={"textContainer2"}>
            <p className={"flexibleText"}>
              We have a flexible set of lending criteria that can be tailored to youWe have a
              flexible set of lending criteria that can
            </p>
          </div>
          <a href={process.env.REACT_APP_REGISTRATION_URL} target="_blank">
            <Button className={"letsButton"} onClick={() => null}>
              {"LET'S GET STARTED"}&nbsp;
              {<ArrowRightShort />}
            </Button>
          </a>
        </div>
      </div>
      <div className={"headerDown"}>
        {cardData &&
          cardData.map((data, index) => (
            <CardContainer
              width={39}
              height={39}
              headerTitle={data.title}
              headerSubtitle={data.subTitle}
              icon={IMAGES.note_icon.toString()}
              mainContainerStyle={"cardMain"}
              subContainerStyle={"cardSub"}
              iconStyle={"cardIcon"}
              headerTitleStyle={"cardTitle"}
              headerSubtitleStyle={"cardSubtitle"}
            />
          ))}
      </div>
    </div>
  );
};

//for deployement

export default HeaderSection;
