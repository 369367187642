import React from "react";
import { Image } from "react-bootstrap";
//ASSETS
import { IMAGES } from "../../assets";
import "./styles/callOurExpert.css";

//CSS
// const styles: { [key: string]: React.CSSProperties } = {
//   expertContainer: {
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//   },
//   callBgImg: {
//     position: "relative",
//     top: -20,
//     left: -20,
//   },
//   call: {
//     position: "relative",
//     top: -20,
//     left: -43,
//   },
// };

interface CallOurExpertProps {
  color?: string;
  contactNumber?: string;
  expertText?: string;
}

const CallOurExpert = (props: CallOurExpertProps) => {
  // const contactNo: React.CSSProperties = {
  //   fontSize: "22px",
  //   fontWeight: 600,
  //   fontFamily: "century_gothicregular",
  //   color: props.color === "white" ? COLORS.white : COLORS.black,
  //   margin: 0,
  // };
  // const expertText: React.CSSProperties = {
  //   fontSize: "18px",
  //   fontWeight: 300,
  //   fontFamily: "century_gothicregular",
  //   color: props.color === "white" ? COLORS.white : COLORS.black,
  //   margin: 0,
  // };

  return (
    <div className="expertSection">
      <div>
        <Image
          src={IMAGES.girl_sticker.toString()}
          alt={"expert_call-logo"}
          width={65}
          height={65}
        />
        <Image
          src={IMAGES.call_background.toString()}
          alt={"call_background-logo"}
          className="callBgImage"
        />
        <Image src={IMAGES.call.toString()} alt={"call_background-logo"} className="call" />
      </div>
      <div>
        <p className={props.contactNumber}>+1 564 6778 88978</p>
        <p className={props.expertText}>Call our expert</p>
      </div>
    </div>
  );
};

export default CallOurExpert;
