import React from "react";
import { Form, Image, InputGroup } from "react-bootstrap";
//COMMON COMPONENT
import ButtonComponent from "../common/ButtonComponent";
//ASSETS
import { COLORS, IMAGES } from "../../assets";
import "./styles/reachOut.css";
//CSS
// const mainContainer: React.CSSProperties = {
//   height: "auto",
//   padding: "80px 0px 80px 0px",
// };
// const subContainer: React.CSSProperties = {
//   maxWidth: "50%",
// };
// const imageContainer: React.CSSProperties = {
//   backgroundImage: `url(${IMAGES.pattern.toString()})`,
//   backgroundRepeat: "no-repeat",
//   backgroundPositionY: "100px",
//   backgroundSize: "240px",
//   minWidth: 670,
//   marginRight: 10,
//   marginTop: 60,
// };
// const container: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
// };

// const aboutCompany: React.CSSProperties = {
//   fontSize: "18px",
//   fontFamily: "century_gothicregular",
//   color: COLORS.black_light,
//   fontWeight: 600,
//   lineHeight: "32px",
// };
// const mainTitle: React.CSSProperties = {
//   fontSize: "52px",
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   fontWeight: 700,
//   lineHeight: "130%",
//   marginBottom: "4%",
// };
// const specificationMainContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   // width: "100%",
// };
// const specificationContainer: React.CSSProperties = {
//   width: 300,
//   height: 87,
//   backgroundColor: COLORS.light_blue,
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "center",
//   alignItems: "center",
//   borderRadius: 8,
//   marginRight: "2%",
// };
// const specificationValue: React.CSSProperties = {
//   fontSize: "17px",
//   fontFamily: "century_gothicregular",
//   color: COLORS.purple,
//   fontWeight: 600,
//   lineHeight: "110%",
//   margin: "5px",
// };
// const fieldContainer: React.CSSProperties = {
//   marginTop: 25,
//   marginBottom: 25,
//   width: "98%",
// };
const field: React.CSSProperties = {
  backgroundColor: COLORS.light_blue,
  fontFamily: "century_gothicregular",
  fontSize: "22px",
  lineHeight: "44px",
  letterSpacing: "0.02em",
  border: 0,
  height: 60,
  borderRadius: 10,
  padding: "0px 0px 0px 30px",
  color: COLORS.black,
};
const ReachOut = () => {
  return (
    <div>
      <div className="reachOutSection">
        <div className="leftSide">
          <img src={IMAGES.reach_out_main} alt="" />
        </div>
        <div className="rightSide">
          <p className="aboutCompany">ABOUT OUR COMPANY</p>
          <p className="mainTitle">
            Solve Your
            <br /> Automotive Lending
            <br /> Needs Here Today!
          </p>
          <div className="specificationMainContainer">
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <div className="reach-out-specition-container" key={index}>
                  <Image
                    src={IMAGES.note_icon.toString()}
                    alt={"note_icon"}
                    width={39}
                    height={39}
                    style={{ margin: "5px" }}
                  />
                  <p className="specificationValue">Vehicle Specifications</p>
                </div>
              ))}
          </div>
          <InputGroup className="fieldContainer">
            <Form.Control style={field} placeholder="Full Name" />
          </InputGroup>
          <InputGroup className="fieldContainer">
            <Form.Control style={field} placeholder="Email Address" />
          </InputGroup>
          <InputGroup className="fieldContainer">
            <Form.Control style={field} placeholder="Phone Number" />
          </InputGroup>
          <ButtonComponent
            buttonName={"Reach Out Today".toLocaleUpperCase()}
            width={309}
            height={72}
            isArrow={true}
            buttonContainerStyle="buttonContainer"
            buttonStyleNormal="button"
          />
        </div>
      </div>
      {/* <div style={container}>
        <div style={imageContainer}>
          <Image
            src={IMAGES.reach_out_main.toString()}
            alt={"about-section-img"}
            width={550}
            height={550}
            style={{ marginLeft: "11.5%" }}
          />
        </div>
        <div style={subContainer}>
          <p style={aboutCompany}>ABOUT OUR COMPANY</p>
          <p style={mainTitle}>
            Solve Your
            <br /> Automotive Lending
            <br /> Needs Here Today!
          </p>
          <div style={specificationMainContainer}>
            {Array(3)
              .fill(null)
              .map((_, index) => (
                <div
                  style={specificationContainer}
                  className="reach-out-specition-container"
                  key={index}
                >
                  <Image
                    src={IMAGES.note_icon.toString()}
                    alt={"note_icon"}
                    width={39}
                    height={39}
                  />
                  <p style={specificationValue}>Vehicle Specifications</p>
                </div>
              ))}
          </div>
          <InputGroup style={fieldContainer}>
            <Form.Control style={field} placeholder="Full Name" />
          </InputGroup>
          <InputGroup style={fieldContainer}>
            <Form.Control style={field} placeholder="Email Address" />
          </InputGroup>
          <InputGroup style={fieldContainer}>
            <Form.Control style={field} placeholder="Phone Number" />
          </InputGroup>
          <ButtonComponent
            buttonName={"Reach Out Today".toLocaleUpperCase()}
            width={309}
            height={72}
            isArrow={true}
          />
        </div>
      </div> */}
    </div>
  );
};

export default ReachOut;
