// import React from "react";
import { Image } from "react-bootstrap";
//ASSETS
import { IMAGES } from "../../assets/index";

//COMMOM COMPONENTS
import ButtonComponent from "./ButtonComponent";
import "./styles/manageAccount.css";

//CSS
// const mainContainer: React.CSSProperties = {
//   backgroundColor: COLORS.thick_blue,
//   height: 262,
//   display: "flex",
//   justifyContent: "center",
//   paddingLeft: 100,
//   paddingRight: 150,
//   backgroundImage: `url(${IMAGES.mask_footer.toString()})`,
//   backgroundRepeat: "no-repeat",
//   backgroundSize: "cover",
//   marginTop: "5%",
// };
// const subContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   alignItems: "center",
//   alignContent: "center",
//   alignSelf: "center",
//   width: "100%",
// };
// const innerContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-around",
//   alignItems: "center",
// };
// const textContainer: React.CSSProperties = {
//   marginLeft: 15,
// };
// const heading: React.CSSProperties = {
//   fontSize: 20,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   margin: 0,
// };
// const subHeading: React.CSSProperties = {
//   fontSize: 35,
//   fontFamily: "century_gothicregular",
//   fontWeight: 900,
//   color: COLORS.white,
//   margin: 0,
// };

const ManageAccount = () => {
  return (
    <div className="manageAccountSection">
      <div className="subContainer">
        <div className="innerContainer">
          <Image width={60} height={60} src={IMAGES.note_icon.toString()} />
          <div className="textContainer">
            <p className="headingManage">AUTO DEBT CAPITAL</p>
            <p className="subHeading">MANAGE YOUR ACCOUNT</p>
          </div>
        </div>
        <div>
          <a href="https://staging-app.autodebtcapital.com/">
            <ButtonComponent
              buttonName={"Login"}
              isArrow={true}
              isBlur={true}
              buttonStyleNormal="button"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ManageAccount;
