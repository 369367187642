// import React from "react";
import { Button, Image } from "react-bootstrap";
import { ArrowRightShort } from "react-bootstrap-icons";
import { IMAGES } from "../../assets";
//Component props
interface ButtonProps {
  buttonName: string;
  onClick?: () => void;
  height?: number;
  width?: number;
  isArrow?: boolean;
  variant?: string;
  size?: number;
  arrowImage?: boolean;
  fontWeight?: number;
  isBlur?: boolean;
  btnRadius?: number;
  fontSize?: number;
  buttonContainerStyle?: string;
  buttonStyleWithVariant?: string;
  buttonStyleNormal?: string;
}
const ButtonComponent = (props: ButtonProps) => {
  const { buttonName, onClick, isArrow, variant } = props;

  return (
    <div className={props.buttonContainerStyle}>
      {variant ? (
        <Button variant={variant} className={props.buttonStyleWithVariant} onClick={onClick}>
          {buttonName}&nbsp;
          {isArrow ? <ArrowRightShort /> : null}
        </Button>
      ) : (
        <Button
          // style={{
          //   width: width,
          //   height: height,
          //   background: isBlur ? "rgba(10, 178, 250, 0.7)" : "rgba(10, 178, 250)",
          //   fontSize: size,
          //   fontWeight: props.fontWeight ? props.fontWeight : 700,
          //   borderRadius: btnRadius ? btnRadius : 12,
          //   fontFamily: "century_gothicregular",
          //   backdropFilter: isBlur ? "blur(0.5px)" : "",
          // }}
          className={props.buttonStyleNormal}
          onClick={onClick}
        >
          {buttonName}&nbsp;
          {isArrow ? <ArrowRightShort /> : null}
          {props.arrowImage ? (
            <Image
              src={IMAGES.arrow_right.toString()}
              alt={"ellipse"}
              width={10}
              height={10}
            ></Image>
          ) : null}
        </Button>
      )}
    </div>
  );
};

export default ButtonComponent;
