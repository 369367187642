// import React from "react";
import CardContainer from "../common/CardContainer";
//ASSETS
import { IMAGES } from "../../assets";
import "./styles/ourServiceSectionSecond.css";

//CSS
// const mainContainer: React.CSSProperties = {
//   width: "100%",
//   height: "845px",
//   backgroundImage: `url(${IMAGES.service_background.toString()})`,
//   backgroundPosition: "center",
//   backgroundRepeat: "no-repeat",
//   backgroundSize: "cover",
//   padding: "110px 0px 120px 0px",
// };
// const ourServiceText: React.CSSProperties = {
//   textAlign: "center",
//   textTransform: "uppercase",
//   fontWeight: 600,
//   fontSize: "18px",
//   lineHeight: "32px",
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   marginBottom: "1%",
// };

// const HeaderText: React.CSSProperties = {
//   fontSize: "52px",
//   fontWeight: 700,
//   lineHeight: "100%",
//   textAlign: "center",
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   marginBottom: "1%",
// };

// const subText: React.CSSProperties = {
//   fontSize: "21px",
//   fontWeight: 300,
//   lineHeight: "40px",
//   letterSpacing: "0.02em",
//   textAlign: "center",
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   marginTop: "0.5%",
// };

// const subContainer: React.CSSProperties = {
//   width: 257,
//   height: 245,
//   padding: 20,
//   borderRadius: "22px",
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   background: "rgba(255, 255, 255, 0.06)",
//   backdropFilter: "blur(9.5px)",
//   marginRight: 30,
//   marginTop: "25%",
// };

// const mainTitle: React.CSSProperties = {
//   fontSize: 20,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   margin: 0,
//   fontWeight: 700,
//   paddingTop: "4%",
//   // lineHeight: "-0.462554px",
// };

// const subTitle: React.CSSProperties = {
//   fontSize: 14,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   margin: 0,
//   fontWeight: 400,
//   lineHeight: "28px",
// };

// const cardStyle: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "center",
//   marginTop: 35,
// };

const OurServiceSectionSecond = () => {
  const cardArray = [
    {
      headerTitle: "Same Day Approvals",
      headerSubtitle:
        "Leveraging our propriatary technology, often times we are able to approve loans same day!",
    },
    {
      headerTitle: "Same Day Approvals",
      headerSubtitle:
        "Leveraging our propriatary technology, often times we are able to approve loans same day!",
    },
    {
      headerTitle: "Same Day Approvals",
      headerSubtitle:
        "Leveraging our propriatary technology, often times we are able to approve loans same day!",
    },
    {
      headerTitle: "Same Day Approvals",
      headerSubtitle:
        "Leveraging our propriatary technology, often times we are able to approve loans same day!",
    },
  ];

  return (
    <div className="ourServiceSectionSecond">
      <div className="ourServiceText">Our Service</div>
      <div className="headerText">Covering All Your Lending Needs</div>
      <div className="subText">
        We are capable of offering creative solutions to your lending needs. Check Out Our Offerings
        Below!
      </div>
      <div className="cardStyle">
        {cardArray.map((item) => (
          <CardContainer
            headerTitle={item.headerTitle}
            headerSubtitle={item.headerSubtitle}
            icon={IMAGES.note_icon.toString()}
            subContainerStyle="subContainer"
            headerTitleStyle="mainTitle"
            headerSubtitleStyle="subTitle"
            width={40}
            height={40}
          />
        ))}
      </div>
    </div>
  );
};

export default OurServiceSectionSecond;
