import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { IMAGES } from "../../assets";
import { SwiperSlide, Swiper } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Zoom, Autoplay, EffectCoverflow } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./styles/customerCard.css";
interface CardData {
  id: number;
  title: string;
  subTitle: string;
  image: string;
}

//Card Data
const customerData: CardData[] = [
  {
    id: 1,
    title: "Got a tough decision to make?",
    subTitle: "Turn to Barack Obama and Jeff Bezos’s strategies.",
    image: IMAGES.customer_1.toString(),
  },
  {
    id: 2,
    title: "Want to stay calm under stress?",
    subTitle: "Use the techniques of Nelson Mandela and the Dalai Lama.",
    image: IMAGES.customer_2.toString(),
  },
  {
    id: 3,
    title: "Got a tough decision to make?",
    subTitle: "Turn to Barack Obama and Jeff Bezos’s strategies.",
    image: IMAGES.customer_1.toString(),
  },
  {
    id: 1,
    title: "Got a tough decision to make?",
    subTitle: "Turn to Barack Obama and Jeff Bezos’s strategies.",
    image: IMAGES.customer_1.toString(),
  },
  {
    id: 2,
    title: "Want to stay calm under stress?",
    subTitle: "Use the techniques of Nelson Mandela and the Dalai Lama.",
    image: IMAGES.customer_2.toString(),
  },
  {
    id: 3,
    title: "Got a tough decision to make?",
    subTitle: "Turn to Barack Obama and Jeff Bezos’s strategies.",
    image: IMAGES.customer_1.toString(),
  },
];

//Card type
type CustomerCardProps = {
  headerTitle?: string;
  customerTitle?: string;
};

const CustomerCard = (props: CustomerCardProps) => {
  SwiperCore.use([Navigation, Pagination, Zoom, Autoplay, EffectCoverflow]);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const width = window.innerWidth;

  return (
    <>
      <div className={props.headerTitle ? "mainContainer2" : "mainContainer1"}>
        {props.headerTitle ? <p className="headerTitle">TESTIMONIAL</p> : null}
        <p className="heading">
          {props.customerTitle ? props.customerTitle : "What our customers are talking about"}
        </p>
        <Swiper
          pagination={{ clickable: true }}
          spaceBetween={20}
          initialSlide={1}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          watchSlidesProgress={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 35,
            modifier: 1,
            slideShadows: false,
          }}
          slidesPerView={width > 600 ? 2 : 1}
          centeredSlides
          className="swiperStyle"
          onActiveIndexChange={(index: any) => setSwiperIndex(index.activeIndex)}
        >
          {customerData &&
            customerData.map((data, index) => (
              <SwiperSlide>
                <div
                  key={index}
                  className={
                    swiperIndex === index ? "cardSubContainerActive" : "cardSubContainerInActive"
                  }
                >
                  <div className="innerContainer">
                    <div className="textContainer">
                      <p className="mainTitle">{data.title}</p>
                      <p className="subTitle">{data.subTitle}</p>
                    </div>
                    <Image className="cardImage" src={data.image} />
                  </div>
                  <div className="quoteImage">
                    <Image width={60} height={60} src={IMAGES.double_quotes.toString()} />
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
};

export default CustomerCard;
