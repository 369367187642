import React from "react";
import AboutSection from "../components/About/AboutSection";
import Career from "../components/About/Career";
import Leadership from "../components/About/Leadership";
import CustomerCard from "../components/common/CustomerCard";
import Footer from "../components/common/Footer";
import { Header } from "../components/common/Header";
import ManageAccount from "../components/common/ManageAccount";

//ASSETS
import { COLORS, IMAGES } from "../assets";

//CSS
const heading: React.CSSProperties = {
  fontFamily: "century_gothicregular",
  fontWeight: 800,
  color: COLORS.white,
  fontSize: 67,
  textAlign: "center",
  marginTop: "5%",
  paddingBottom: "15%",
};

const AboutUs = () => {
  return (
    <div>
      <div className="imgbox" style={{ backgroundImage: `url(${IMAGES.about_us_header})` }}>
        <Header />
        <p className="aboutUsText">ABOUT US</p>
      </div>
      <AboutSection />
      <Career />
      <Leadership />
      <CustomerCard headerTitle="Testimonial" customerTitle="What our customers say" />
      <ManageAccount />
      <Footer />
    </div>
  );
};

export default AboutUs;
