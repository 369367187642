import Footer from "../components/common/Footer";
import { Header } from "../components/common/Header";
import ManageAccount from "../components/common/ManageAccount";
import HeaderSection from "../components/Team/HeaderSection";
import TeamInfo from "../components/Team/TeamInfo";

//Assets
import { IMAGES } from "../assets";

const Team = () => {
  return (
    <div>
      <div className="imgbox teamHeader" style={{ backgroundImage: `url(${IMAGES.team_header})` }}>
        <Header />
        <HeaderSection />
      </div>
      <TeamInfo />
      <ManageAccount />
      <Footer />
    </div>
  );
};

export default Team;
