import React, { useState } from "react";
import { Image } from "react-bootstrap";
// import { Search } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
// import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";

//ASSETS
import { COLORS, IMAGES } from "../../assets";
import ButtonComponent from "./ButtonComponent";

import "./styles/header.css";

//CSS
const headerTitles: React.CSSProperties = {
  color: COLORS.white,
  paddingRight: "45px",
  fontFamily: "century_gothicregular",
  fontSize: "18px",
  fontWeight: 800,
  margin: 0,
  cursor: "pointer",
};
export const Header = () => {
  const navigate = useNavigate();

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };
  console.log("sidebar : ", sidebar);
  return (
    <>
      <div className={"header_container"}>
        <div className="menu-icon" onClick={showSidebar}>
          <Image src={IMAGES.hamburger.toString()} width={24} height={15} alt="menu" />
        </div>
        <div className={"logoContainer"}>
          <Image src={IMAGES.auto_debt_logo.toString()} className={"logo"} alt="logo" />
        </div>
        <div className={"nav-elements"}>
          <p style={headerTitles} onClick={() => navigate("/")}>
            HOME
          </p>
          <p style={headerTitles} onClick={() => navigate("/about")}>
            ABOUT
          </p>
          {/* <p style={headerTitles} onClick={() => navigate("/insurance")}>
            INSURANCE
          </p> */}
          <p style={headerTitles} onClick={() => navigate("/team")}>
            TEAM
          </p>
          <p style={headerTitles} onClick={() => navigate("/news")}>
            NEWS
          </p>
          <div>
            <a href="https://staging-app.autodebtcapital.com/">
              <ButtonComponent
                width={150}
                height={45}
                buttonStyleNormal={"headerLoginButton"}
                size={18}
                buttonName={"Login"}
                btnRadius={8}
                // isArrow={true}
                isBlur={true}
              />
            </a>
          </div>{" "}
        </div>
        {/* <nav className={sidebar ? "side-menu active" : "side-menu"}></nav> */}
      </div>
      <Offcanvas show={sidebar} onHide={showSidebar}>
        <Offcanvas.Header style={{ marginTop: 20, marginLeft: 25 }}>
          <div style={{ cursor: "pointer" }} onClick={showSidebar}>
            <img src={IMAGES.close.toString()} className={"close-icon"} alt="logo" />
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ margin: 20 }}>
          <div
            className={"side-nav-box"}
            onClick={() => {
              showSidebar();
              navigate("/");
            }}
          >
            <div className={"side-home-icon"}></div>
            <p className={"side-nav-item"}>HOME</p>
          </div>
          <div
            className={"side-nav-box"}
            onClick={() => {
              showSidebar();
              navigate("/about");
            }}
          >
            <div className={"side-about-icon"}></div>
            <p className={"side-nav-item"}>ABOUT</p>
          </div>
          {/* <div className={"side-nav-box"} onClick={() => navigate("/insurance")}>
            <img src={IMAGES.insurance.toString()} className={"side-nav-icon"} alt="logo" />
            <p className={"side-nav-item"}>INSURANCE</p>
          </div> */}
          <div
            className={"side-nav-box"}
            onClick={() => {
              showSidebar();
              navigate("/team");
            }}
          >
            <div className={"side-team-icon"}></div>
            <p className={"side-nav-item"}>TEAM</p>
          </div>
          <div
            className={"side-nav-box"}
            onClick={() => {
              showSidebar();
              navigate("/news");
            }}
          >
            <div className={"side-news-icon"}></div>
            <p className={"side-nav-item"}>NEWS</p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
