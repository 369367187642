import React from "react";
import { Image } from "react-bootstrap";

//ASSETS
import { IMAGES } from "../../assets";
import ButtonComponent from "./ButtonComponent";

interface CardContainerProps {
  width?: number;
  height?: number;
  headerTitle: string;
  headerSubtitle: string;
  icon?: string;
  mainContainerStyle?: any | React.CSSProperties;
  subContainerStyle?: any | React.CSSProperties;
  iconStyle?: any | React.CSSProperties;
  headerTitleStyle: any | React.CSSProperties;
  headerSubtitleStyle: any | React.CSSProperties;
  iconWithTitle?: boolean;
  isHover?: boolean;
  hoverIndex?: number;
  index?: number;
  btnOuterDiv?: string;
}
const CardContainer = (props: CardContainerProps) => {
  return (
    <div className={props.mainContainerStyle}>
      <div className={props.subContainerStyle}>
        {props.icon && !props.iconWithTitle ? (
          <Image
            width={props.width}
            height={props.height}
            className={props.iconStyle}
            src={IMAGES.note_icon.toString()}
          />
        ) : null}
        <div>
          {props.iconWithTitle ? (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Image
                  width={props.width}
                  height={props.height}
                  className={props.iconStyle}
                  src={props.icon}
                />
                <p className={props.headerTitleStyle}>{props.headerTitle}</p>
              </div>
              <p className={props.headerSubtitleStyle}>{props.headerSubtitle}</p>
            </>
          ) : (
            <>
              <p className={props.headerTitleStyle}>{props.headerTitle}</p>
              <p className={props.headerSubtitleStyle}>{props.headerSubtitle}</p>
            </>
          )}
          {props.isHover && props.hoverIndex == props.index && (
            <div style={{ marginTop: 10 }} className={props.btnOuterDiv}>
              <ButtonComponent
                size={14}
                buttonStyleNormal={"learnButton"}
                fontWeight={600}
                buttonName="LEARN MORE"
                height={40}
                width={150}
                arrowImage={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardContainer;
