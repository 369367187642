import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./screens/Dashboard";
import Insurance from "./screens/Insurance";
import AboutUs from "./screens/AboutUs";
import News from "./screens/News";

import Team from "./screens/Team";
import { ThemeProvider } from "react-bootstrap";

function App() {
  return (
    <div>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/insurance" element={<Insurance />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/news" element={<News />} />
            <Route path="/team" element={<Team />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
