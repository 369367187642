// import React from "react";
// import { Col, Container, Image, Row } from "react-bootstrap";
import ButtonComponent from "../common/ButtonComponent";
// import CallOurExpert from "../common/CallOurExpert";
import "./styles/aboutOurCompany.css";

//ASSETS
import { IMAGES } from "../../assets";

//CSS
// const mainContainer: React.CSSProperties = {
//   height: "auto",
//   padding: "80px 60px 80px 0px",
//   backgroundColor: COLORS.white,
// };
// const container: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
// };

// const aboutText: React.CSSProperties = {
//   fontSize: "18px",
//   fontFamily: "century_gothicregular",
//   fontWeight: 600,
//   lineHeight: "32px",
//   margin: 0,
// };

// const aboutHeading: React.CSSProperties = {
//   fontSize: "52px",
//   fontFamily: "century_gothicregular",
//   fontWeight: 700,
//   margin: 0,
//   marginTop: 20,
//   lineHeight: "130%",
// };

// const aboutDetail: React.CSSProperties = {
//   fontSize: "21px",
//   lineHeight: "40px",
//   letterSpacing: "0.02em",
//   width: "65%",
//   marginTop: 20,
//   color: COLORS.black,
//   fontFamily: "century_gothicregular",
// };

// const aboutArrayStyle: React.CSSProperties = {
//   display: "flex",
//   flexFlow: "wrap",
//   marginTop: 25,
// };

// const aboutArraySubStyle: React.CSSProperties = {
//   display: "flex",
//   justifyContent: "left",
//   width: "37%",
//   alignItems: "center",
//   marginBottom: "20px",
// };

// const aboutArrayInnerStyle: React.CSSProperties = {
//   margin: 0,
//   paddingLeft: "13px",
//   fontFamily: "century_gothicregular",
//   fontSize: "22px",
//   lineHeight: "40px",
//   fontWeight: 600,
//   letterSpacing: "0.02em",
// };

// const callToExpert: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
//   marginTop: 25,
// };

// const dicoverMore: React.CSSProperties = {
//   marginRight: "5%",
// };

// const imageContainer: React.CSSProperties = {
//   backgroundImage: `url(${IMAGES.pattern.toString()})`,
//   backgroundRepeat: "no-repeat",
//   backgroundPositionY: "100px",
//   backgroundSize: "240px",
//   minWidth: 670,
//   marginRight: 10,
// };

//test comment

const AboutOurCompany = () => {
  // const aboutArray: string[] = [
  //   "A Modern Approach To Lending.",
  //   "Creative Lending Opportunities.",
  //   "Same Day Approvals.",
  //   "Loans Up To 10 Million.",
  // ];

  return (
    <div className="aboutSection">
      <div className="leftSide">
        <img src={IMAGES.about_section} alt="" />
      </div>
      <div className="rightSide">
        <div className="subHeading">ABOUT OUR COMPANY</div>
        <h2 className="autoHeading">Auto Debt Capital</h2>
        <p>
          Our parent company has been around for 15 years and our executive team has a combined 32
          years of industry knowledge in Finance, Leasing and Automotive sales. We are here to
          provide a modern approach to lending for dealership floorplans, and personal auto loans.
          We use software and data to provide a streamlined solutions to our clients.
        </p>
        <ul>
          <li>A Modern Approach To Lending.</li>
          <li>Creative Lending Opportunities.</li>
          <li>Same Day Approvals.</li>
          <li>Loans Up To 10 Million.</li>
        </ul>

        <div className="sectionFooter">
          <ButtonComponent buttonName="DISCOVER MORE" isArrow={true} />

          <div className="expertCall">
            <div className="expertIcon">
              <img src={IMAGES.girl_sticker} alt="" />
              <span>
                <img src={IMAGES.call} alt="" />
              </span>
            </div>
            <h3>
              +1 564 6778 88978 <span>Call our expert</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOurCompany;
