import React from "react";
//COMMON COMPONENT
import CardContainer from "../common/CardContainer";
//ASSETS
import { COLORS, IMAGES } from "../../assets";
//CSS
const cardSubContainer: React.CSSProperties = {
  width: 330,
  height: 150,
  padding: 25,
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  background: "#F3F9FE",
  boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.15)",
};

const mainTitle: React.CSSProperties = {
  fontSize: "14px",
  fontFamily: "century_gothicregular",
  color: COLORS.black,
  margin: 0,
  fontWeight: 300,
  lineHeight: "150%",
  textTransform: "uppercase",
  marginBottom: 10,
};

const subTitle: React.CSSProperties = {
  fontSize: "26px",
  fontFamily: "century_gothicregular",
  color: COLORS.black,
  margin: 0,
  fontWeight: 700,
  lineHeight: "150%",
  textTransform: "uppercase",
};

const cardStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
};

const iconStyle: React.CSSProperties = {
  marginRight: 10,
};

type CardArray = {
  title: string;
  subTitle: string;
};

const CardSection = () => {
  const cardArray: CardArray[] = [
    {
      title: "Lowest Price",
      subTitle: "50% + Insurance with lowest price",
    },
    {
      title: "discount & Savings",
      subTitle: "Save up to 50% in our policy",
    },
    {
      title: "User Friendly",
      subTitle: "Quick easy and hassle free",
    },
  ];

  return (
    <div style={cardStyle}>
      {cardArray.map((item) => (
        <CardContainer
          headerTitle={item.title}
          headerSubtitle={item.subTitle}
          icon={IMAGES.vector.toString()}
          subContainerStyle={cardSubContainer}
          headerTitleStyle={mainTitle}
          headerSubtitleStyle={subTitle}
          width={15}
          height={15}
          iconWithTitle={true}
          iconStyle={iconStyle}
        />
      ))}
    </div>
  );
};

export default CardSection;
