// import React from "react";
import { Image } from "react-bootstrap";
//ASSETS
import { IMAGES } from "../../assets";
//CSS
import "./styles/leadership.css";

// const mainContainer: React.CSSProperties = {
//   width: "100%",
//   padding: 20,
//   marginTop: "3%",
//   marginBottom: "3%",
// };
// const leaderTitle: React.CSSProperties = {
//   fontSize: 18,
//   fontFamily: "century_gothicregular",
//   color: COLORS.sky_blue,
//   margin: 0,
//   textAlign: "center",
// };
// const heading: React.CSSProperties = {
//   fontSize: 42,
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   margin: 0,
//   textAlign: "center",
//   marginTop: 10,
// };
// const leadershipContainer: React.CSSProperties = {
//   display: "flex",
//   justifyContent: "space-between",
//   padding: "20px 0px 20px 0px",
//   marginRight: "2%",
//   marginLeft: "2%",
// };
// const leadershipSubContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   width: "23%",
//   justifyContent: "center",
//   alignItems: "center",
// };
// const leaderName: React.CSSProperties = {
//   fontSize: 24,
//   fontFamily: "century_gothicregular",
//   color: COLORS.thick_blue,
//   margin: 0,
//   paddingTop: "6%",
//   fontWeight: 700,
// };
// const leaderPosition: React.CSSProperties = {
//   fontSize: 24,
//   fontFamily: "century_gothicregular",
//   color: COLORS.sky_blue,
//   margin: 0,
// };

type LeadershipData = {
  image: string;
  name: string;
  position: string;
};

//Leaders data
const leadershipArray: LeadershipData[] = [
  {
    image: IMAGES.president.toString(),
    name: "Scott Maybee",
    position: "President",
  },
  {
    image: IMAGES.vp_finance.toString(),
    name: "Scott Maybee",
    position: "VP of Finance",
  },
  {
    image: IMAGES.vp_marketing.toString(),
    name: "Scott Maybee",
    position: "VP of Marketing",
  },
  {
    image: IMAGES.avp_slaes.toString(),
    name: "Scott Maybee",
    position: "AVP of Sales",
  },
];

const Leadership = () => {
  return (
    <div className="mainContainer">
      <p className="leaderTitle">TEAM</p>
      <p className="leadershipHeading">Leadership Team</p>
      <div className="leadershipContainer">
        {leadershipArray.map((data, index) => (
          <div className="leadershipSubContainer" key={index}>
            <Image src={data.image} className="leaderImage" alt={"leaders"} />
            <p className="leaderName">{data.name}</p>
            <p className="leaderPosition">{data.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leadership;
