import React from "react";

//ASSETS
import { IMAGES } from "../../assets";

//COMMON COMPONENTS
import ButtonComponent from "../common/ButtonComponent";

//CSS
const mainContainer: React.CSSProperties = {
  width: "100%",
  height: "auto",
  backgroundImage: `url(${IMAGES.who_we_are.toString()})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  marginTop: 150,
  padding: "80px 0px 80px 120px",
};

const headerText: React.CSSProperties = {
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "32px",
  textTransform: "uppercase",
  color: "#42C9F0",
  fontFamily: "century_gothicregular",
};

const headerMainText: React.CSSProperties = {
  fontWeight: 700,
  fontSize: "42px",
  lineHeight: "69px",
  textTransform: "uppercase",
  color: "#000000",
  letterSpacing: "0.02em",
  fontFamily: "century_gothicregular",
};

const subText: React.CSSProperties = {
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "46px",
  color: "#000000",
  letterSpacing: "0.02em",
  fontFamily: "century_gothicregular",
};

const WhoWeAre = () => {
  return (
    <div style={mainContainer}>
      <div style={{ width: "50%" }}>
        <div style={headerText}>Who we are</div>
        <div style={headerMainText}>
          We’re one of World’s largest Insurance Agency throughout the USA & Canada
        </div>
        <div style={subText}>
          We offer careers across our divisions, with dozens of brands in multiple locations. That
          means there are plenty of opportunities for you to move up, move around and explore new
          things – all under the umbrella of a company that cares. Here are a few featured career
          areas:
        </div>
        <div style={{ marginTop: 20 }}>
          <ButtonComponent buttonName="GET A QUOTE" height={72} width={228} isArrow={false} />
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
