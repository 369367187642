import React from "react";
import { Image } from "react-bootstrap";

//ASSETS
import { IMAGES } from "../../assets";

//CSS
import "./styles/teamInfo.css";

// const infoMain1: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   marginTop: "5%",
//   marginBottom: "10%",
// };
// const infoMain2: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   marginTop: "5%",
//   marginBottom: "5%",
// };
// const leftSideContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   marginLeft: "4%",
//   width: "50%",
// };
// const rightSideContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   marginRight: "4%",
//   marginLeft: "12%",
//   width: "45%",
// };
// const memberName: React.CSSProperties = {
//   fontSize: 42,
//   fontFamily: "century_gothicregular",
//   color: COLORS.thick_blue,
//   fontWeight: 700,
//   lineHeight: "69px",
//   letterSpacing: "0.02em",
//   margin: 0,
// };
// const memberProfile: React.CSSProperties = {
//   fontSize: 26,
//   fontFamily: "century_gothicregular",
//   color: COLORS.sky_blue,
//   fontWeight: 400,
//   lineHeight: "150%",
//   letterSpacing: "0.01em",
//   margin: 0,
//   marginBottom: "3%",
// };
// const memberDesc: React.CSSProperties = {
//   fontSize: 18,
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   fontWeight: 400,
//   margin: 0,
//   lineHeight: "46px",
//   letterSpacing: "0.02em",
// };
type TeamDataType = {
  name: string;
  profile: string;
  desc: string;
  image: string;
};

//Team data
const teamData: TeamDataType[] = [
  {
    name: "Scott Maybee",
    profile: "President",
    desc: "Scott Maybee is president of NextGear Capital, the automotive industry’s largest independent inventory finance company serving more than 19,000 contracted independent dealer clients in North America. Scott brings 10 years of automotive finance experience to the position, providing strategic leadership and new opportunities for independent auto dealers to profitably operate and grow their businesses. As the industry evolves, Scott and his leadership team also are pursuing ways to optimize the digital experience to drive efficiencies in every aspect of the client relationship.",
    image: IMAGES.president_team.toString(),
  },
  {
    name: "Colletta Bryce",
    profile: "AVP HRBP",
    desc: "Colletta Bryce is the AVP, HRBPs for Cox Automotive (Auto) leading the team that partners with the Corporate Services, NextGear Capital and People Solutions. She joined Cox Auto in December 2016 as a Senior Director, serving as the business partner for the former People Strategies. Prior to Cox Auto, Colletta held Executive and Leader roles at CertusBank, Bank of America and Wachovia since 2002. She’s held other roles in non-profit and sales. Colletta is a graduate of Pfeiffer University with a B.S. in Business Administration and an M.S. in Organizational Change and Leadership. She also attended Broadcasting School and is a certified Diversity Practitioner.",
    image: IMAGES.avp_hrbp_team.toString(),
  },
  {
    name: "David Horan",
    profile: "VP of Finance",
    desc: "David Horan serves as VP of Finance for NextGear Capital. He is responsible for executing the company’s financial and strategic planning. Horan worked as Corporate Controller for JD Byrider Systems, the largest franchiser of auto sales/finance dealerships in the U.S. He also served as Corporate Controller for Auction Broadcasting Company, the third-largest auto auction chain in the nation. In 2005, Horan joined Dealer Services Corporation as Corporate Controller and Assistant Treasurer, designing and managing the accounting and treasury functions for DSC.",
    image: IMAGES.vp_finance_team.toString(),
  },
  {
    name: "Eric Wright",
    profile: "AVP Assistant General Counsel",
    desc: "Eric Wright serves as AVP, Assistant General Counsel for the Cox Automotive Financial Solutions Group. In his role, he leads a team of legal professionals who provide support and counsel to the NextGear Capital business. Eric has been with the company for 12 years. He has more than 30 years of experience as an attorney, including twenty-plus years in the floor planning industry. Eric is a graduate of Carthage College with a Bachelor of Arts in History. He also holds a Doctor of Jurisprudence from the Valparaiso University School of Law.",
    image: IMAGES.avp_councel_team.toString(),
  },
  {
    name: "Floyd Alcorn",
    profile: "AVP of Product and Engineering",
    desc: "Floyd Alcorn is NextGear Capital’s Associate Vice President of Product and Engineering responsible for leading multiple software, infrastructure and production engineering teams. He brings nearly three decades of technology industry experience to the position with a proven record of delivering complex programs at scale while driving value for clients and the business. Floyd holds a Bachelor of Science in Physics from Principia College in Illinois and a Master of Science in Strategic Management from Georgia State University.",
    image: IMAGES.avp_engineering_team.toString(),
  },
  {
    name: "Jessica Lanier",
    profile: "VP of Marketing",
    desc: "As Vice President of Marketing for both Manheim and NextGear Capital, Jessica is responsible for aligning the marketing objectives of both organizations with the overall business strategy of Cox Automotive. Jessica is a 22-year veteran of Cox Automotive, where she has guided teams across five different brands. Her passion for aligning the right tools and technology to optimize and take marketing to the next level led to her role as the Executive Sponsor for the Cox Automotive MarTech team.",
    image: IMAGES.vp_marketing_team.toString(),
  },
  {
    name: "John Wick",
    profile: "SVP of Lending",
    desc: "John Wick serves as the SVP of Lending and for NextGear Capital. In the role of Lending, he leads the company’s credit, risk and recovery operations that covers all facets from customer induction to dissolution. John is one of the founding members of Dealer Services Corporation, joining the company in 2005 as General Counsel. Prior to joining the company, he was a partner in the Indianapolis law firm Lewis, Wick & Koski, where his primary areas of practice included litigation, debtor and creditor relations and business law.",
    image: IMAGES.svp_leading_team.toString(),
  },
  {
    name: "Kyle Ballinger",
    profile: "AVP of Sales",
    desc: "Kyle is a 17-year Cox Automotive veteran and comes to us from Manheim where he most recently led the Regional Dealer Sales team for the Midwest market. I had the opportunity to work closely with Kyle when I was the General Manager of Manheim Northstar and look forward to seeing his passion for moving the business forward here at NextGear Capital. His experience, leadership skills and the results he’s achieved make him a perfect fit for our team. Kyle is a graduate of Xavier University with a Bachelor of Arts in advertising. He lives just outside Cincinnati with his wife and two children. He enjoys coaching his son’s basketball team and serving on the Board of Directors for The Dobbs Family Foundation.",
    image: IMAGES.avp_sales_team.toString(),
  },
  {
    name: "Nicole Graham-Ponce",
    profile: "VP of Portfolio Management",
    desc: "Nicole has been with Manheim for 24 years leading operational and sales teams, including serving as General Manager of the Dallas-Forth Worth location for the last 10 years. Nicole attended the University of Nebraska-Lincoln and lives in the Dallas-Fort Worth area. She loves to spend time with her family and her three dogs. In addition, she is the President of the National Auto Auction Association Western Chapter.",
    image: IMAGES.vp_portfolio_team.toString(),
  },
];

const teamDataAlt: TeamDataType[] = [
  {
    name: "Scott Maybee",
    profile: "President",
    desc: "Scott Maybee is president of NextGear Capital, the automotive industry’s largest independent inventory finance company serving more than 19,000 contracted independent dealer clients in North America. Scott brings 10 years of automotive finance experience to the position, providing strategic leadership and new opportunities for independent auto dealers to profitably operate and grow their businesses. As the industry evolves, Scott and his leadership team also are pursuing ways to optimize the digital experience to drive efficiencies in every aspect of the client relationship.",
    image: IMAGES.team_member1.toString(),
  },
  {
    name: "Colletta Bryce",
    profile: "AVP HRBP",
    desc: "Colletta Bryce is the AVP, HRBPs for Cox Automotive (Auto) leading the team that partners with the Corporate Services, NextGear Capital and People Solutions. She joined Cox Auto in December 2016 as a Senior Director, serving as the business partner for the former People Strategies. Prior to Cox Auto, Colletta held Executive and Leader roles at CertusBank, Bank of America and Wachovia since 2002. She’s held other roles in non-profit and sales. Colletta is a graduate of Pfeiffer University with a B.S. in Business Administration and an M.S. in Organizational Change and Leadership. She also attended Broadcasting School and is a certified Diversity Practitioner.",
    image: IMAGES.team_member2.toString(),
  },
  {
    name: "David Horan",
    profile: "VP of Finance",
    desc: "David Horan serves as VP of Finance for NextGear Capital. He is responsible for executing the company’s financial and strategic planning. Horan worked as Corporate Controller for JD Byrider Systems, the largest franchiser of auto sales/finance dealerships in the U.S. He also served as Corporate Controller for Auction Broadcasting Company, the third-largest auto auction chain in the nation. In 2005, Horan joined Dealer Services Corporation as Corporate Controller and Assistant Treasurer, designing and managing the accounting and treasury functions for DSC.",
    image: IMAGES.team_member3.toString(),
  },
  {
    name: "Eric Wright",
    profile: "AVP Assistant General Counsel",
    desc: "Eric Wright serves as AVP, Assistant General Counsel for the Cox Automotive Financial Solutions Group. In his role, he leads a team of legal professionals who provide support and counsel to the NextGear Capital business. Eric has been with the company for 12 years. He has more than 30 years of experience as an attorney, including twenty-plus years in the floor planning industry. Eric is a graduate of Carthage College with a Bachelor of Arts in History. He also holds a Doctor of Jurisprudence from the Valparaiso University School of Law.",
    image: IMAGES.team_member4.toString(),
  },
  {
    name: "Floyd Alcorn",
    profile: "AVP of Product and Engineering",
    desc: "Floyd Alcorn is NextGear Capital’s Associate Vice President of Product and Engineering responsible for leading multiple software, infrastructure and production engineering teams. He brings nearly three decades of technology industry experience to the position with a proven record of delivering complex programs at scale while driving value for clients and the business. Floyd holds a Bachelor of Science in Physics from Principia College in Illinois and a Master of Science in Strategic Management from Georgia State University.",
    image: IMAGES.team_member5.toString(),
  },
  {
    name: "Jessica Lanier",
    profile: "VP of Marketing",
    desc: "As Vice President of Marketing for both Manheim and NextGear Capital, Jessica is responsible for aligning the marketing objectives of both organizations with the overall business strategy of Cox Automotive. Jessica is a 22-year veteran of Cox Automotive, where she has guided teams across five different brands. Her passion for aligning the right tools and technology to optimize and take marketing to the next level led to her role as the Executive Sponsor for the Cox Automotive MarTech team.",
    image: IMAGES.team_member6.toString(),
  },
  {
    name: "John Wick",
    profile: "SVP of Lending",
    desc: "John Wick serves as the SVP of Lending and for NextGear Capital. In the role of Lending, he leads the company’s credit, risk and recovery operations that covers all facets from customer induction to dissolution. John is one of the founding members of Dealer Services Corporation, joining the company in 2005 as General Counsel. Prior to joining the company, he was a partner in the Indianapolis law firm Lewis, Wick & Koski, where his primary areas of practice included litigation, debtor and creditor relations and business law.",
    image: IMAGES.team_member7.toString(),
  },
  {
    name: "Kyle Ballinger",
    profile: "AVP of Sales",
    desc: "Kyle is a 17-year Cox Automotive veteran and comes to us from Manheim where he most recently led the Regional Dealer Sales team for the Midwest market. I had the opportunity to work closely with Kyle when I was the General Manager of Manheim Northstar and look forward to seeing his passion for moving the business forward here at NextGear Capital. His experience, leadership skills and the results he’s achieved make him a perfect fit for our team. Kyle is a graduate of Xavier University with a Bachelor of Arts in advertising. He lives just outside Cincinnati with his wife and two children. He enjoys coaching his son’s basketball team and serving on the Board of Directors for The Dobbs Family Foundation.",
    image: IMAGES.team_member8.toString(),
  },
  {
    name: "Nicole Graham-Ponce",
    profile: "VP of Portfolio Management",
    desc: "Nicole has been with Manheim for 24 years leading operational and sales teams, including serving as General Manager of the Dallas-Forth Worth location for the last 10 years. Nicole attended the University of Nebraska-Lincoln and lives in the Dallas-Fort Worth area. She loves to spend time with her family and her three dogs. In addition, she is the President of the National Auto Auction Association Western Chapter.",
    image: IMAGES.team_member9.toString(),
  },
];
const TeamInfo = () => {
  return (
    <>
      <div className={"teamInfo"}>
        {teamData.map((data, index) => (
          <>
            {index % 2 === 0 ? (
              <div className={"teamInfoMain"} key={index}>
                <div className={"leftInfo"}>
                  <p className={"memberName"}>{data.name}</p>
                  <p className={"memberPost"}>{data.profile}</p>
                  <p className={"memberDesc"}>{data.desc}</p>
                </div>
                <div>
                  <Image src={data.image} className={"teamMemberImage"} alt="profile" />
                </div>
              </div>
            ) : (
              <div className={"teamInfoMainAlternate"} key={index}>
                <div>
                  <Image src={data.image} className={"teamMemberImage"} alt="profile" />
                </div>
                <div className={"rightInfo"}>
                  <p className={"memberName"}>{data.name}</p>
                  <p className={"memberPost"}>{data.profile}</p>
                  <p className={"memberDesc"}>{data.desc}</p>
                </div>
              </div>
            )}
          </>
        ))}
      </div>
      <div className={"teamInfoAlt"}>
        {teamDataAlt.map((data, index) => (
          <>
            <div className={"teamInfoAltMain"} key={index}>
              <div>
                <p className={"memberNameAlt"}>{data.name}</p>
                <p className={"memberPostAlt"}>{data.profile}</p>
              </div>
              <div>
                <Image src={data.image} className={"teamMemberImageAlt"} alt="profile" />
              </div>

              <p className={"memberDescAlt"}>{data.desc}</p>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default TeamInfo;
