import React from "react";
//ASSETS
import { COLORS, IMAGES } from "../../assets";

//COMMON COMPONENTS
import CardContainer from "../common/CardContainer";

//CSS
const mainContainer: React.CSSProperties = {
  width: "100%",
  marginBottom: "3%",
  marginTop: "5%",
};

const headerText: React.CSSProperties = {
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "32px",
  textTransform: "uppercase",
  color: "#42C9F0",
  textAlign: "center",
  fontFamily: "century_gothicregular",
  marginBottom: "0.6%",
};

const mainHeaderText: React.CSSProperties = {
  fontWeight: 700,
  fontSize: "42px",
  lineHeight: "69px",
  textTransform: "capitalize",
  textAlign: "center",
  fontFamily: "century_gothicregular",
};

const cardSubContainer0: React.CSSProperties = {
  width: "100%",
  padding: 25,
  borderRadius: "5px 5px 85px 5px",
  display: "flex",
  flexDirection: "column",
  background: "#E7FAFF",
};

const cardSubContainer1: React.CSSProperties = {
  width: "100%",
  padding: 25,
  borderRadius: "5px 85px",
  display: "flex",
  flexDirection: "column",
  background: "#E7FAFF",
};

const cardSubContainer2: React.CSSProperties = {
  width: "100%",
  padding: 25,
  borderRadius: "85px 5px 5px 5px",
  display: "flex",
  flexDirection: "column",
  background: "#E7FAFF",
};

const mainTitle: React.CSSProperties = {
  fontSize: "20px",
  fontFamily: "century_gothicregular",
  color: COLORS.thick_blue,
  margin: 0,
  fontWeight: 600,
  lineHeight: "32px",
  textTransform: "uppercase",
  letterSpacing: "0.02em",
  marginBottom: 10,
};

const subTitle: React.CSSProperties = {
  fontSize: "18px",
  fontFamily: "century_gothicregular",
  color: COLORS.thick_blue,
  margin: 0,
  fontWeight: 400,
  lineHeight: "46px",
  letterSpacing: "0.02em",
};
const imgStyle: React.CSSProperties = {
  backgroundImage: `url(${IMAGES.provided_services.toString()})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "45% 100%",
  marginTop: 50,
  width: "100%",
};

const cardStyle: React.CSSProperties = {
  width: "100%",
  maxWidth: "100%",
  display: "flex",
  flexFlow: "wrap",
  paddingLeft: "38%",
  paddingTop: "4.5%",
  gap: "20px",
  paddingBottom: "4.5%",
};
const mainContainerStyle: React.CSSProperties = {
  width: "calc(45% - 20px)",
};

type providedSectionsCardsType = {
  title: string;
  description: string;
};

const ProvidedServices = () => {
  const providedSectionsCards: providedSectionsCardsType[] = [
    {
      title: "Personal accident cover",
      description:
        "Another advantage of car insurance is that it offers personal accident cover for a pre-determined amount. Personal Accident cover provides protection against permanent total disability, Death due to an accident. Furthermore, this cover can be taken for other passengers on unnamed basis for a pre-determined amount under the car.",
    },
    {
      title: "Personal accident cover",
      description:
        "Another advantage of car insurance is that it offers personal accident cover for a pre-determined amount. Personal Accident cover provides protection against permanent total disability, Death due to an accident. Furthermore, this cover can be taken for other passengers on unnamed basis for a pre-determined amount under the car.",
    },
    {
      title: "Personal accident cover",
      description:
        "Another advantage of car insurance is that it offers personal accident cover for a pre-determined amount. Personal Accident cover provides protection against permanent total disability, Death due to an accident. Furthermore, this cover can be taken for other passengers on unnamed basis for a pre-determined amount under the car.",
    },
    {
      title: "Personal accident cover",
      description:
        "Another advantage of car insurance is that it offers personal accident cover for a pre-determined amount. Personal Accident cover provides protection against permanent total disability, Death due to an accident. Furthermore, this cover can be taken for other passengers on unnamed basis for a pre-determined amount under the car.",
    },
  ];

  return (
    <div style={mainContainer}>
      <div style={headerText}>Provided Services</div>
      <div style={mainHeaderText}>All the services that our Company provides</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={imgStyle}>
          <div style={cardStyle}>
            {providedSectionsCards.map((item, index) => (
              <CardContainer
                headerTitle={item.title}
                headerSubtitle={item.description}
                subContainerStyle={
                  index === 0
                    ? cardSubContainer0
                    : index === 3
                    ? cardSubContainer2
                    : cardSubContainer1
                }
                headerTitleStyle={mainTitle}
                headerSubtitleStyle={subTitle}
                mainContainerStyle={mainContainerStyle}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProvidedServices;
