import React from "react";
import { Form, Image, InputGroup } from "react-bootstrap";
import { COLORS, IMAGES } from "../../assets";
import ButtonComponent from "./ButtonComponent";
import CallOurExpert from "./CallOurExpert";
import "./styles/footer.css";

const Footer = () => {
  return (
    <div className="footerSection">
      <div className="subContainer">
        {/* Logo and social account section */}
        <div className="firstContainer">
          <Image src={IMAGES.auto_debt_logo.toString()} alt={"logo"} className="logo" />
          <div className="socialContainerDesktop">
            <p className="socialHeading">Get Going</p>
            <div className="socialContainer">
              <Image
                src={IMAGES.facebook.toString()}
                alt={"facebook-logo"}
                width={41}
                height={41}
              />
              <Image src={IMAGES.twitter.toString()} alt={"twitter-logo"} width={41} height={41} />
              <Image
                src={IMAGES.instagram.toString()}
                alt={"instagram-logo"}
                width={41}
                height={41}
              />
              <Image
                src={IMAGES.pinterest.toString()}
                alt={"pinterest-logo"}
                width={41}
                height={41}
              />
            </div>
          </div>
        </div>
        {/* Second div */}
        <div className="contactContainer">
          <p className="contactHeading">CONTACT</p>
          <div className="contactSubContainer">
            <Image src={IMAGES.mail.toString()} alt={"mail-logo"} width={20} height={16} />
            <p className="contactValue">info@autodebtcapital.com</p>
          </div>
          <div className="contactSubContainer">
            <Image src={IMAGES.location.toString()} alt={"location-logo"} width={20} height={16} />
            <p className="contactValue">South Florida</p>
          </div>
          <div className="openHoursContainer">
            <p className="contactHeading">OPEN HOURS</p>
            <p className="contactTime">
              Mon – Sat: 8:00 am to 6:00 pm <br className="brDisable" /> Sunday: Closed
            </p>
          </div>
        </div>
        {/* Third div */}
        <div className="newsLetterContainer">
          <p className="newsLetterHeading">NEWS LETTER</p>
          <p className="newsLetterSubtitle">
            Subscribe our news letter to get our latest
            <br /> update & news.
          </p>
          <div style={fieldParent}>
            <InputGroup style={fieldContainer}>
              <InputGroup.Text style={fieldLogo}>
                <Image src={IMAGES.mail.toString()} alt={"mail-logo"} width={20} height={16} />
              </InputGroup.Text>
              <Form.Control style={field} placeholder="Email Address" />
            </InputGroup>
            <div style={{ alignSelf: "center", marginTop: 20, width: "30%" }}>
              <ButtonComponent
                size={12}
                fontWeight={600}
                buttonName="Submit"
                height={35}
                width={100}
                btnRadius={5}
                arrowImage={true}
              />
            </div>
          </div>
          <CallOurExpert contactNumber="contactNumber" expertText="expertText" />
          <div className="socialContainerMobile">
            <p className="socialHeading">Get Going</p>
            <div className="socialContainer">
              <Image
                src={IMAGES.facebook.toString()}
                alt={"facebook-logo"}
                width={41}
                height={41}
              />
              <Image src={IMAGES.twitter.toString()} alt={"twitter-logo"} width={41} height={41} />
              <Image
                src={IMAGES.instagram.toString()}
                alt={"instagram-logo"}
                width={41}
                height={41}
              />
              <Image
                src={IMAGES.pinterest.toString()}
                alt={"pinterest-logo"}
                width={41}
                height={41}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bottomContainer">
        <span style={dividerLine} />
        <p className="bottomText">© All Copyright 2022 | AutoDebtCapital.com</p>
      </div>
    </div>
  );
};
// const mainContainer: React.CSSProperties = {
//   width: "100%",
//   height: 500,
//   backgroundColor: COLORS.blue,
//   padding: "80px 60px 10px 60px",
// };
// const subContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
// };
// const firstContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   width: "25%",
//   marginLeft: "8%",
// };
// const social_heading: React.CSSProperties = {
//   fontSize: 18,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
// };
// const socialContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "space-between",
//   width: 200,
// };
// const contactContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   // backgroundColor: "aqua",
//   width: "25%",
// };
// const contactHeading: React.CSSProperties = {
//   fontSize: 19,
//   fontFamily: "century_gothicregular",
//   fontWeight: 600,
//   color: COLORS.white,
// };
// const contactSubContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
//   marginBottom: 5,
// };
// const contactValue: React.CSSProperties = {
//   fontSize: 16,
//   fontFamily: "century_gothicregular",
//   paddingLeft: 10,
//   margin: 0,
//   color: COLORS.light_gray_alt,
// };
// const contactTime: React.CSSProperties = {
//   fontSize: 16,
//   fontFamily: "century_gothicregular",
//   color: COLORS.light_gray_alt,
//   padding: 0,
//   margin: 0,
//   marginTop: 5,
// };
// const newsLetterContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   // marginTop: 75,
//   width: "30%",
// };
// const newsLetterHeading: React.CSSProperties = {
//   fontSize: 19,
//   fontFamily: "century_gothicregular",
//   fontWeight: 600,
//   color: COLORS.white,
// };
// const newsLetterSubtitle: React.CSSProperties = {
//   fontSize: 16,
//   fontFamily: "century_gothicregular",
//   margin: 0,
//   color: COLORS.light_gray_alt,
// };
const fieldParent: React.CSSProperties = {
  display: "flex",
  // justifyContent: "space-between",
  marginBottom: 15,
};
const fieldContainer: React.CSSProperties = {
  marginTop: 20,
  width: "70%",
  marginRight: 15,
};
const fieldLogo: React.CSSProperties = {
  backgroundColor: "#010e2599",
  border: 0,
};
const field: React.CSSProperties = {
  backgroundColor: "#010e2599",
  border: 0,
  color: COLORS.white,
};
const dividerLine: React.CSSProperties = {
  display: "flex",
  flexDirection: "row",
  border: "1px solid #ffffff66",
};
// const bottomText: React.CSSProperties = {
//   fontSize: 16,
//   fontFamily: "century_gothicregular",
//   color: COLORS.light_gray_alt,
//   padding: 0,
//   margin: 0,
//   textAlign: "center",
//   marginTop: 25,
// };

// const bottomContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "column",
//   marginTop: 80,
// };

export default Footer;
