import React, { CSSProperties } from "react";
import { COLORS, IMAGES } from "../../assets";
import Accordion from "react-bootstrap/Accordion";

//CSS
import "./styles/sideBar.css";

const newCategories: string[] = [
  "Blogs",
  "Community Relations News",
  "Company News",
  "FAQs",
  "Getting Started",
  "Industry Tips",
  "Spotlights",
  "The Way Forwards",
  "Tutorials",
];

const resourceCategories: string[] = ["Case Studies", "Videos", "Tutorials"];

const recentPost: string[] = [
  "Powering you with responsive service",
  "Powering you with responsive service",
  "Powering you with responsive service",
  "Powering you with responsive service",
];

const SideBar = () => {
  return (
    <>
      <div className="sideBarOuter">
        <div style={styles.sideBarContainer}>
          <p style={styles.headingText}>News Categories</p>
          {newCategories.map((item, index) => {
            return (
              <SideBarItem
                name={item}
                index={index}
                lastIndex={newCategories.length - 1}
                type={1}
              />
            );
          })}

          <br />
          <p style={styles.headingText}>Resource Categories</p>

          {resourceCategories.map((item, index) => {
            return (
              <SideBarItem
                name={item}
                index={index}
                lastIndex={resourceCategories.length - 1}
                type={1}
              />
            );
          })}

          <br />
          <p style={styles.headingText}>Recent Posts</p>

          {recentPost.map((item, index) => {
            return (
              <SideBarItem name={item} index={index} lastIndex={recentPost.length - 1} type={2} />
            );
          })}
        </div>
        <div style={styles.advertiseImg}>
          <div style={styles.advertiseTextContanier}>
            <p style={styles.advText}>Advertise</p>
          </div>
        </div>
      </div>
      <div className="accordionStyle">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{"News Categories"}</Accordion.Header>
            <Accordion.Body>
              {newCategories.map((item, index) => {
                return (
                  <SideBarItem
                    name={item}
                    index={index}
                    lastIndex={newCategories.length - 1}
                    type={1}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>{"Resource Categories"}</Accordion.Header>
            <Accordion.Body>
              {resourceCategories.map((item, index) => {
                return (
                  <SideBarItem
                    name={item}
                    index={index}
                    lastIndex={resourceCategories.length - 1}
                    type={1}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>{"Recent posts"}</Accordion.Header>
            <Accordion.Body>
              {recentPost.map((item, index) => {
                return (
                  <SideBarItem
                    name={item}
                    index={index}
                    lastIndex={recentPost.length - 1}
                    type={2}
                  />
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
};

type Props = {
  type: number;
  name: string;
  index: number;
  lastIndex: number;
};

const SideBarItem = ({ type, name, index, lastIndex }: Props) => {
  return (
    <div style={styles.itemContainerMain}>
      <div style={styles.itemContainer}>
        {type === 1 && <div style={styles.circle}></div>}
        <p className="nameText">{name}</p>
      </div>
      {index !== lastIndex && <div style={styles.line}></div>}
    </div>
  );
};

//CSS
const styles: { [key: string]: CSSProperties } = {
  sideBarOuter: {
    marginRight: 30,
    marginTop: 15,
    width: "35%",
    minHeight: 1100,
  },
  sideBarContainer: {
    background: COLORS.light_blue_2,
    display: "flex",
    flexDirection: "column",
    alignItems: " flex-start",
    padding: "20px 30px",
    borderRadius: 20,
  },

  headingText: {
    color: COLORS.black,
    fontWeight: 700,
    fontSize: 26,
    marginBottom: 20,
    textTransform: "uppercase",
    fontFamily: "century_gothicregular",
  },
  itemContainerMain: {
    marginBottom: 15,
  },
  itemContainer: {
    display: "flex",
    width: 340,
  },
  line: {
    background: COLORS.light_blue_alt,
    color: COLORS.light_blue_alt,
    borderTop: "1px solid",
  },
  circle: {
    borderRadius: 20,
    height: 20,
    width: 20,
    border: "3px solid #42C9F0",
    borderColor: COLORS.sky_blue,
    marginRight: 8,
    marginTop: 3,
  },
  nameText: {
    fontWeight: 400,
    fontSize: 18,
    color: COLORS.black,
    fontFamily: "century_gothicregular",
  },
  advertiseImg: {
    width: "100%",
    height: 360,
    marginTop: 30,
    backgroundPosition: "center",
    background: `url(${IMAGES.news_image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    borderRadius: 25,
  },

  advertiseTextContanier: {
    height: 80,
    background: "rgba(255, 255, 255, 0.7)",
    overflow: "hidden",
    justifyContent: "center",
    display: "flex",
  },
  advText: {
    fontSize: 20,
    color: COLORS.black,
    textTransform: "capitalize",
    fontWeight: 700,
    alignSelf: "center",
    fontFamily: "century_gothicregular",
  },
};

export default SideBar;
