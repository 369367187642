import React from "react";
import { Image } from "react-bootstrap";

//ASSETS
import { IMAGES } from "../../assets";

//COMMON COMPONENTS
import ButtonComponent from "../common/ButtonComponent";

//CSS

import "./styles/headerSection.css";

// const mainContainer: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
//   justifyContent: "space-between",
//   padding: 30,
// };
// const container1: React.CSSProperties = {
//   width: "45%",
//   marginLeft: "5%",
// };
// const container2: React.CSSProperties = {
//   width: "40%",
// };
// const heading: React.CSSProperties = {
//   fontSize: 60,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   margin: 0,
//   fontWeight: 800,
// };
// const subHeading: React.CSSProperties = {
//   fontSize: 24,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   margin: 0,
// };
// const buttonStyle: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   marginTop: "5%",
// };

const HeaderSection = () => {
  return (
    <div className={"teamHeaderMain"}>
      <div className={"left"}>
        <p className={"p1"}>About Our Perfect Team</p>
        <p className={"p2"}>
          We have a flexible set of lending criteria that can be tailored to youWe have a flexible
          set of lending criteria that can
        </p>
        <div className={"btnContainer"}>
          <ButtonComponent
            buttonStyleNormal={"btn1"}
            buttonName="GET A QUOTE"
            height={72}
            width={228}
            isArrow={false}
          />
          <ButtonComponent
            buttonStyleWithVariant={"btn2"}
            buttonName="HIRE OUR TEAM"
            height={72}
            width={228}
            isArrow={false}
            variant={"outline"}
          />
        </div>
      </div>
      <div className={"right"}>
        <Image src={IMAGES.team_group.toString()} alt="team-group" className={"rightImage"} />
      </div>
    </div>
  );
};

export default HeaderSection;
