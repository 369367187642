import { useState } from "react";
import CardContainer from "../common/CardContainer";
//ASSETS
import { IMAGES } from "../../assets";

import { SwiperSlide, Swiper } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Zoom, Autoplay, EffectCoverflow } from "swiper";

//CSS
import "./styles/ourServiceSectionFirst.css";

// const mainContainer: React.CSSProperties = {
//   width: "100%",
//   height: "auto",
//   backgroundColor: "#FFFFFF",
//   padding: "80px 0px 80px 0px",
//   marginTop: "2%",
// };

// const ourServiceText: React.CSSProperties = {
//   textAlign: "center",
//   textTransform: "uppercase",
//   fontWeight: 600,
//   fontSize: "18px",
//   lineHeight: "32px",
//   fontFamily: "century_gothicregular",
//   marginBottom: "1%",
// };

// const HeaderText: React.CSSProperties = {
//   fontSize: "52px",
//   fontWeight: 700,
//   lineHeight: "130%",
//   textAlign: "center",
//   fontFamily: "century_gothicregular",
//   marginBottom: "1%",
// };

// const subText: React.CSSProperties = {
//   fontSize: "21px",
//   fontWeight: 300,
//   lineHeight: "40px",
//   letterSpacing: "0.02em",
//   textAlign: "center",
//   fontFamily: "century_gothicregular",
// };

// const imagesStyle: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   justifyContent: "center",
//   marginTop: 35,
// };

// const subContainer: React.CSSProperties = {
//   width: 280,
//   height: 130,
//   padding: 20,
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",
//   background: "rgba(255, 255, 255, 0.06)",
//   backdropFilter: "blur(9.5px)",
// };

// const subContainerOnHover: React.CSSProperties = {
//   width: 280,
//   height: 220,
//   padding: 20,
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   alignItems: "center",
//   background: "rgba(255, 255, 255, 0.06)",
//   backdropFilter: "blur(5.5px)",
// };

// const mainTitle: React.CSSProperties = {
//   fontSize: 20,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   margin: 0,
//   fontWeight: 700,
//   lineHeight: "-0.462554px",
// };

// const subTitle: React.CSSProperties = {
//   fontSize: 14,
//   fontFamily: "century_gothicregular",
//   color: COLORS.white,
//   margin: 0,
//   fontWeight: 400,
//   lineHeight: "28px",
// };

type imageArray = {
  image: string;
  headerTitle: string;
  headerSubtitle: string;
};
//Image's array
const ImageArray: imageArray[] = [
  {
    image: IMAGES.service1.toString(),
    headerTitle: "Same Day Approvals",
    headerSubtitle:
      "Leveraging our propriatary technology, often times we are able to approve loans same day!",
  },
  {
    image: IMAGES.service2.toString(),
    headerTitle: "Same Day Approvals",
    headerSubtitle:
      "Leveraging our propriatary technology, often times we are able to approve loans same day!",
  },
  {
    image: IMAGES.service3.toString(),
    headerTitle: "Same Day Approvals",
    headerSubtitle:
      "Leveraging our propriatary technology, often times we are able to approve loans same day!",
  },
  {
    image: IMAGES.service4.toString(),
    headerTitle: "Same Day Approvals",
    headerSubtitle:
      "Leveraging our propriatary technology, often times we are able to approve loans same day!",
  },
];
const OurServiceSectionFirst = () => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [hoverIndex, setHoverIndex] = useState<number>(-1);
  const [swiperIndex, setSwiperIndex] = useState(0);
  SwiperCore.use([Navigation, Pagination, Zoom, Autoplay, EffectCoverflow]);

  console.log("swiper Index : ", swiperIndex);

  return (
    <div className={"ourSecFirst"}>
      <div className={"ourServiceText"}>Our Service</div>
      <div className={"coveringText"}>Covering All Your Lending Needs</div>
      <div className={"capableText"}>
        We are capable of offering creative solutions to your lending needs. Check Out Our Offerings
        Below!
      </div>
      <div className={"imagesContainer"}>
        {ImageArray.map((item, index) => (
          <div
            className={"hoverImage imageStyle"}
            style={{
              backgroundImage: `url(${item.image})`,
            }}
            onMouseEnter={() => {
              setIsHover(true);
              setHoverIndex(index);
            }}
            onMouseLeave={() => {
              setIsHover(false);
              setHoverIndex(-1);
            }}
          >
            <CardContainer
              headerTitle={item.headerTitle}
              headerSubtitle={item.headerSubtitle}
              subContainerStyle={"cardSub"}
              headerTitleStyle={"cardTitle"}
              headerSubtitleStyle={"cardSubtitle"}
              isHover={isHover}
              hoverIndex={hoverIndex}
              index={index}
            />
          </div>
        ))}
      </div>
      <div className={"showSwiper"}>
        <Swiper
          // className="imagesContainer"
          effect="coverflow"
          coverflowEffect={{
            rotate: 0,
            stretch: 10,
            depth: 35,
            modifier: 1,
            slideShadows: false,
          }}
          grabCursor={true}
          centeredSlides={true}
          spaceBetween={60}
          slidesPerView={2}
          initialSlide={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          // style={{ paddingLeft: 20, paddingRight: 20 }}
          onActiveIndexChange={(index: any) => setSwiperIndex(index.activeIndex)}
        >
          {ImageArray.map((item, index) => (
            <SwiperSlide>
              <div
                className={swiperIndex === index ? "imageStyleAlt" : "imageStyle"}
                style={{
                  backgroundImage: `url(${item.image})`,
                }}
              >
                <CardContainer
                  headerTitle={item.headerTitle}
                  headerSubtitle={item.headerSubtitle}
                  subContainerStyle={swiperIndex === index ? "cardSubAlt" : "cardSub"}
                  headerTitleStyle={"cardTitle"}
                  headerSubtitleStyle={"cardSubtitle"}
                  btnOuterDiv={"btnOuterDiv"}
                  isHover={true}
                  hoverIndex={swiperIndex}
                  index={index}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OurServiceSectionFirst;
