// import React from "react";
import { Image } from "react-bootstrap";
import { IMAGES } from "../../assets";
import ButtonComponent from "../common/ButtonComponent";
import CallOurExpert from "../common/CallOurExpert";
import "./styles/aboutSection.css";

// const mainContainer: React.CSSProperties = {
//   height: "auto",
//   padding: "80px 0px 80px 0px",
// };
// const container: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
// };
// const subContainer: React.CSSProperties = {
//   paddingLeft: "5%",
// };

// const aboutHeading: React.CSSProperties = {
//   fontSize: "52px",
//   fontFamily: "century_gothicregular",
//   fontWeight: 700,
//   lineHeight: "86px",
//   letterSpacing: "0.02em",
// };

// const aboutDetail: React.CSSProperties = {
//   fontSize: "21px",
//   lineHeight: "46px",
//   letterSpacing: "0.02em",
//   fontFamily: "century_gothicregular",
//   color: COLORS.black,
//   width: "80%",
// };

// const aboutArrayStyle: React.CSSProperties = {
//   margin: "35px 0px 35px 0px",
//   display: "flex",
//   flexFlow: "wrap",
// };

// const aboutArraySubStyle: React.CSSProperties = {
//   display: "flex",
//   justifyContent: "left",
//   width: "40%",
//   alignItems: "center",
//   marginBottom: "20px",
// };

// const aboutArrayInnerStyle: React.CSSProperties = {
//   margin: 0,
//   paddingLeft: "13px",
//   fontFamily: "century_gothicregular",
//   fontSize: "20px",
//   lineHeight: "40px",
//   fontWeight: 600,
//   letterSpacing: "0.02em",
// };

// const callToExpert: React.CSSProperties = {
//   display: "flex",
//   flexDirection: "row",
//   alignItems: "center",
// };

// const dicoverMore: React.CSSProperties = {
//   marginRight: "5%",
// };

// const imageContainer: React.CSSProperties = {
//   backgroundImage: `url(${IMAGES.pattern.toString()})`,
//   backgroundRepeat: "no-repeat",
//   backgroundPositionX: "right",
//   backgroundPositionY: "30px",
//   backgroundSize: "350px 340px",
//   minWidth: 670,
//   marginTop: "8%",
//   marginLeft: 10,
// };

const AboutSection = () => {
  const aboutArray: string[] = [
    "A Modern Approach To Lending.",
    "Creative Lending Opportunities.",
    "Same Day Approvals.",
    "Loans Up To 10 Million.",
  ];
  return (
    <div className="aboutSectionUs">
      <div className="aboutContainer">
        <div className="subContainer">
          <div className="aboutHeading">
            How Auto Debt Capital <br />
            became a leader in dealer <br />
            financing
          </div>
          <div className="aboutDetail">
            Our parent company has been around for 15 years and our executive team has a combined 32
            years of industry knowledge in Finance, Leasing and Automotive sales. We are here to
            provide a modern approach to lending for dealership floorplans, and personal auto loans.
            We use software and data to provide a streamlined solutions to our clients.
          </div>
          <div className="aboutArray">
            {aboutArray.map((item) => (
              <div className="aboutArraySubStyle">
                <Image src={IMAGES.dot.toString()} alt={"ellipse"} width={30} height={30} />
                <span className="aboutArrayInnerStyle">{item}</span>
              </div>
            ))}
          </div>
          <div className="callToExpert">
            <div className="discoverMore">
              <ButtonComponent
                buttonName="DISCOVER MORE"
                buttonStyleNormal="btnStyle"
                isArrow={true}
              />
            </div>
            <div className="callContainer">
              <CallOurExpert contactNumber="contactNumber" expertText="expertText" />
            </div>
          </div>
        </div>
        <div className="imageContainer">
          <Image src={IMAGES.about_section.toString()} alt={"about-section-img"} />
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
