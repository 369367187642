export const COLORS = {
  black: "#000000",
  yellow: "#FBCE60",
  white: "#ffffff",
  gray: "#DFD9D9",
  blue: "#002F86",
  light_gray: "#F7F7F7",
  white_border: "#f3f3f340",
  light_gray_alt: "#ffffff8a",
  white_light: "#ffffff66",
  thick_blue: "#16243D",
  black_light: "#1C1C1C",
  light_blue: "#F3F9FE",
  light_blue_2: "#E7FAFF",
  purple: "#1A0B3B",
  placeholder: "#A6B8C7",
  sky_blue: "#42C9F0",
  light_blue_alt: "#B8EEFE",
  light_blue2: "#E7FAFF",
  button_bg: "#0AB2FA",
};
