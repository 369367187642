import React, { CSSProperties } from "react";
import { COLORS } from "../assets";
import Footer from "../components/common/Footer";
import { Header } from "../components/common/Header";
import ManageAccount from "../components/common/ManageAccount";

//Assest
import { IMAGES } from "../assets";

import "../components/News/styles/newsCard.css";
import "../components/News/styles/newsHeader.css";

//News Components
import NewsCard from "../components/News/NewsCard";
import SideBar from "../components/News/SideBar";

const News = () => {
  const onReadMore = () => {};
  return (
    <>
      <div className="imgbox imgBoxNews">
        <Header />
        <p className="headingNews">NEWS</p>
      </div>
      <div className="cardMainContainer">
        <SideBar />
        <div style={styles.cardsMainContainer}>
          {Array(6)
            .fill(null)
            .map(() => {
              return (
                <NewsCard
                  title={"Powering you with responsive service"}
                  date={"Nov 09, 2022"}
                  subtitle={"As an independent dealer, we know you wear many hats..."}
                  onReadMore={() => onReadMore()}
                />
              );
            })}
        </div>
      </div>
      <ManageAccount />
      <Footer />
    </>
  );
};

//CSS
const styles: { [key: string]: CSSProperties } = {
  heading: {
    fontFamily: "century_gothicregular",
    color: COLORS.white,
    fontSize: 67,
    textAlign: "center",
    marginTop: "5%",
    fontWeight: 600,
  },

  imageBox: {
    height: "600px",
    backgroundImage: `url(${IMAGES.about_us_header})`,
  },
  mainContainer: {
    marginTop: 35,
    marginLeft: "5%",
    marginRight: "5%",
    display: "flex",
    marginBottom: 200,
    // width: "90%",
    // backgroundColor: COLORS.blue,
  },
  cardsMainContainer: {
    display: "flex",
    flexFlow: "wrap",
    justifyContent: "space-evenly",
    width: "100%",
    // backgroundColor: "black",
  },
};

export default News;
